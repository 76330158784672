<template>
    <div class="card" @click="openTask">
        <Status :status="record.status" class="mb-4" />

        <!-- Dates -->
        <p class="mb-2.5 leading-none" v-if="record.date_start_plan || record.dead_line">
            <template v-if="record.date_start_plan">
                <span>{{$moment(record.date_start_plan).format('DD.MM.YYYY')}} </span>
            </template>
            <template v-if="record.dead_line && record.date_start_plan">
                <span class="ml-1 mr-2">
                    <i class="fi fi-rr-arrow-right"></i>
                </span>
            </template>
            <template v-if="record.dead_line">
                <span>{{$moment(record.dead_line).format('DD.MM.YYYY')}} </span>
            </template>
        </p>
        <p class="mb-4 line-clamp-3">{{ record.name }}</p>
        <div class="card__row">
            <div class="card__col truncate">
                <p class="opacity-60">{{ $t('Operator') }}</p>
                <p class="truncate" :title="record.operator.full_name">{{ record.operator.full_name }}</p>
            </div>
            <div class="card__col truncate">
                <p class="opacity-60">{{ $t('Owner') }}</p>
                <p class="truncate" :title="record.owner.full_name">{{ record.owner.full_name }}</p>
            </div>
        </div>
        <template v-if="record.children_count">
            <hr class="card__divider">
            {{ $t('Nested tasks') }} {{ record.children_count }}
        </template>
    </div>
</template>

<script>
import Status from '@apps/vue2TaskComponent/components/TaskStatus.vue'
export default {
    components: {
        Status,
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    methods: {
        async openTask() {
            let query = Object.assign({}, this.$route.query)
            query.task = this.record.id
            if(!this.$route.query.task) 
                this.$router.push({query})
            // else if(this.$route.query.task !== this.item.id)
            //     this.reloadTask(this.item)
        },
    }
}
</script>


<style lang="scss" scoped>
.card {
    padding: 15px;
    background-color: #F0F2FF;
    border-radius: 8px;
    cursor: pointer;
}

.card__divider {
    border-top: 1px solid #000;
    opacity: 0.3;
    margin-top: 20px;
    margin-bottom: 15px;
}

.card__row {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: -5px;

    @media (min-width: 400px) {
        flex-direction: row;
        margin: -5px -15px;
    }
}

.card__col {
    display: flex;
    flex-direction: column;
    margin: 5px;
    @media (min-width: 400px) {
        margin: 5px 15px;
    }
}

</style>