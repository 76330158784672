<template>
    <div>
        <div class="-m-2.5">
            <ListItem 
                v-for="record in listData"
                :key="record.id"
                class="m-2.5"
                :record="record" />
        </div>
        <infinite-loading
            ref="infiniteLoading"
            @infinite="infiniteHandler"
            :identifier="infiniteId"
            :distance="10">
            <div slot="spinner" class="flex items-center justify-center inf_spinner">
                <a-spin />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import ListItem from './ListItem.vue'
export default {
    components: {
        InfiniteLoading,
        ListItem
    },
    props: {
        endpoint: {
            type: String,
            default: null
        },
        params: {
            type: Object,
            default: () => ({})
        },
        tableKey: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            requestParams: {
                ...this.params,
                page: 1,
                page_size: 3,
            },
            infiniteId: new Date()
        }
    },
    computed: {
        listData() {
            return this.$store.state.projects.lists?.[this.tableKey]?.results
        },
        actionPayload() {
            return {
                endpoint: this.endpoint, 
                params: this.requestParams, 
                tableKey: this.tableKey, 
            } 
        }
    },
    beforeDestroy() {
        this.$store.commit('projects/DROP_LIST', { tableKey: this.tableKey})
    },
    methods: {
        async infiniteHandler($state) {
            try {
                const data = await this.$store.dispatch('projects/setList', this.actionPayload)
                if (data?.next) {
                    this.requestParams.page++;
                    $state.loaded();
                } else {
                    $state.complete();
                }
            } catch(error) {
                this.$message.error("Не удалось получить список задач");
                console.error(error);
                $state.complete();
            }
        },
    },

}
</script>